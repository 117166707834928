/* single date picker下の余白を打ち消す */
.SingleDatePickerInput {
  margin-bottom: 0px !important;
}

.DateInput {
  padding: 0;
  width: calc(100% - 52px);
}

.DateInput_input {
  font-size: 16px !important;
  font-weight: 400;
}

/* airblue */
.CalendarDay__selected {
  background: #20aee5 !important;
  color: white !important;
}

.DateInput_input__focused {
  border-bottom: 2px solid #20aee5;
}

.CalendarDay__blocked_out_of_range {
  color: #e4e7e7;
}
