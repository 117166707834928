/* react-virtualize内部で定義されるスタイルを上書きする為にこちらのスタイルを定義している 
 *https://stackoverflow.com/questions/53129735/how-can-i-change-property-overflow 
 */
.ReactVirtualized__Grid__innerScrollContainer {
  overflow: visible !important;
  outline: none;
}

.ReactVirtualized__Table__Grid {
  overflow: visible !important;
  outline: none;
}
