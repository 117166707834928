.air-kit-tooltip {
  margin-right: 16px;
  position: absolute;
  left: -9999px;
  top: -9999px;
}
.air-kit-tooltip-hidden {
  display: none;
}

.air-kit-tooltip-placement-topLeft .arrow {
  left: 11px;
  bottom: -4px;
  transform: rotate(45deg);
}

.air-kit-tooltip-placement-top .arrow {
  left: calc(50% - 6px);
  bottom: -4px;
  transform: rotate(45deg);
}

.air-kit-tooltip-placement-topRight .arrow {
  right: 11px;
  bottom: -4px;
  transform: rotate(45deg);
}

.air-kit-tooltip-placement-leftTop .arrow {
  right: -4px;
  top: 11px;
  transform: rotate(-45deg);
}

.air-kit-tooltip-placement-left .arrow {
  right: -4px;
  top: calc(50% - 6px);
  transform: rotate(-45deg);
}

.air-kit-tooltip-placement-leftBottom .arrow {
  right: -4px;
  bottom: 11px;
  transform: rotate(-45deg);
}

.air-kit-tooltip-placement-bottomLeft .arrow {
  left: 11px;
  top: -4px;
  transform: rotate(-135deg);
}

.air-kit-tooltip-placement-bottom .arrow {
  left: calc(50% - 6px);
  top: -4px;
  transform: rotate(-135deg);
}

.air-kit-tooltip-placement-bottomRight .arrow {
  right: 11px;
  top: -4px;
  transform: rotate(-135deg);
}

.air-kit-tooltip-placement-rightTop .arrow {
  left: -4px;
  top: 11px;
  transform: rotate(135deg);
}

.air-kit-tooltip-placement-right .arrow {
  left: -4px;
  top: calc(50% - 6px);
  transform: rotate(135deg);
}

.air-kit-tooltip-placement-rightBottom .arrow {
  left: -4px;
  bottom: 11px;
  transform: rotate(135deg);
}
