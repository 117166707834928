.mateCalendar > .DayPicker {
  -webkit-animation: show 0.2s linear 0s;
          animation: show 0.2s linear 0s;
}
.mateCalendar.fade > .DayPicker {
  -webkit-animation: hide 0.2s linear 0s;
          animation: hide 0.2s linear 0s;
}

@-webkit-keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes hide {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes hide {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.mateCalendar .CalendarMonth table {
  border-collapse: separate;
}

.mateCalendar .CalendarDay__default {
  border: none;
  font-size: 16px;
  font-weight: 600;
}

.mateCalendar .CalendarDay__selected_span {
  background: #20aee5;
}

.mateCalendar .CalendarDay__selected_span:hover {
  background: #117bb5;
  border: none;
}

.mateCalendar .CalendarDay__selected {
  background: #20aee5;
  border-radius: 20px;
}

.mateCalendar .CalendarDay__selected_start {
  border-radius: 20px 0px 0px 20px;
  background: #20aee5;
}

.mateCalendar .CalendarDay__selected_start:hover {
  background: #117bb5;
}

.mateCalendar .CalendarDay__selected_end {
  border-radius: 0px 20px 20px 0px;
  background: #20aee5;
}

.mateCalendar .CalendarDay__selected_end:hover {
  background: #117bb5;
}

.mateCalendar .CalendarDay__selected_start.CalendarDay__selected_end {
  border-radius: 20px 20px 20px 20px;
}

.mateCalendar .CalendarDay__outside {
  font-weight: 400;
  color: #999;
}

.mateCalendar .CalendarDay__blocked_out_of_range {
  color: #999;
}

.mateCalendar .CalendarDay__hovered_span,
.mateCalendar .CalendarDay__hovered_span:hover {
  background: #e1f0f8;
  border: none;
  color: #999;
}

.mateCalendar .DayPicker_transitionContainer {
  min-height: 360px;
}

.mateCalendar .CalendarDay__blocked_out_of_range:hover {
  border: none;
  color: #999;
}
